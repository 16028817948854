import React, { useState, useEffect } from "react";
import axios from "axios";
import apiMap from "../../apiMapping.json";
import loader from '../../assets/images/loading.gif'
import "./SlokaList.css";


function SlokaList({ language, chapterNumber, selectedSloka, onSelectSlokaNumber }) {
  const [slokas, setSlokas] = useState([]);
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!chapterNumber) return;

    const fetchData = async () => {
      try {
        setLoaded(false)
        const slokaResponse = await axios.get(
          apiMap.hostData +
            apiMap.apiData +
            `sloka/${language}/${chapterNumber}`
        );
        setSlokas(
          Array.isArray(slokaResponse.data)
            ? slokaResponse.data
            : [slokaResponse.data]
        );
        setLoaded(true)
      } catch (err) {
        setError("Failed to fetch sloka data");
        console.error(err);
      }
    };
    fetchData();
  }, [language, chapterNumber]);

  if (error) return <p>{error}</p>;

  return (
    <div className="sloka-list">
      <h4 className="slokas-heading">SLOKAS</h4>
      
      {loaded ? (<div className="slokas">
        {slokas.map((sloka) => (
          <div className={"sloka " + (selectedSloka == sloka.slokaNumber ? "selected" : "")} key={sloka.slokaNumber} onClick={() => onSelectSlokaNumber(sloka.slokaNumber)}>
          <span>{sloka.slokaNumber}</span>
      </div>
        ))}
      </div>) : (<div className='loader'><img src={loader} alt="loading..." /></div>)}
      {/* <table>
        <thead>
          <tr>
            <th>Sloka Number</th>
          </tr>
        </thead>
        <tbody>
          {slokas.map((sloka) => (
            <tr key={sloka.slokaNumber} onClick={() => onSelectSlokaNumber(sloka.slokaNumber)}>
              <td>{sloka.slokaNumber}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  );
}

export default SlokaList;
