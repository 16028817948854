import React, { useState } from 'react';
import './GitaHeader.css'

export const GitaHeader = ({ onLanguageChange, onAudioChange }) => {
    const [language, setLanguage] = useState('en');
    const [audio, setAudio] = useState('male');

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        setLanguage(selectedLanguage);
        onLanguageChange(selectedLanguage);
    };

    const handleAudioChange = (event) => {
        const selectedAudio = event.target.value;
        setAudio(selectedAudio);
        onAudioChange(selectedAudio);
    };

    return (
        <div className='gita-header'>
            <div className='main-menu'>
                <div className='logo'>The Gita</div>
                <div className='nav-items'>
                    <button className='btn' onClick={() => window.open("https://api.thegita.org.in", "_blank")}>API</button>
                    <button className='btn' onClick={() => window.open("https://api.thegita.org.in", "_blank")}>GraphQL</button>
                    <button className='btn' onClick={() => window.open("https://ux.thegita.org.in", "_blank")}>AI Bot</button>
                </div>
            </div>
            <div className='options'>
                <div className="language-list" >
                    <label htmlFor="language">Language:</label>
                    <select value={language} onChange={handleLanguageChange}>
                        <option value="en">English</option>
                        <option value="te">Telugu</option>
                        <option value="hi">Hindi</option>
                    </select>
                    <label htmlFor="audio">Audio:</label>
                    <select value={audio} onChange={handleAudioChange}>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                </div>
            </div>
        </div>
    );
};
