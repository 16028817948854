import React, { useState, useEffect } from "react";
import axios from "axios";
import apiMap from "../../apiMapping.json";
import loader from '../../assets/images/loading.gif'
import "./ChapterList.css";

function ChapterList({ language, selectedChapter,  onSelectChapterNumber }) {
  const [chapters, setChapters] = useState([]);
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoaded(false)
        const chapterResponse = await axios.get(
          apiMap.hostData + apiMap.apiData + `chapterByLanguage/${language}`
        );
        setChapters(
          Array.isArray(chapterResponse.data)
            ? chapterResponse.data
            : [chapterResponse.data]
        );
        setLoaded(true)
      } catch (err) {
        setError("Failed to fetch chapter data");
        console.error(err);
      }
    };
    fetchData();
  }, [language]);

  if (error) return <p>{error}</p>;
  return loaded ? (
    <div className="chapter-list">
      <h4 className="chapters-heading">CHAPTERS</h4>
      <div className="chapters">
        {chapters.map((chapter) => (
          <div className={"chapter " + (selectedChapter == chapter.chapterNumber ? "selected" : "")} key={chapter.chapterNumber} onClick={() => onSelectChapterNumber(chapter.chapterNumber)}>
              <h4>{chapter.chapterNumber}.{chapter.chapterName}</h4>
              <span>{chapter.verseCount} slokas</span>
          </div>
        ))}
      </div>
      {/* <table>
        <thead>
          <tr>
            <th>Chapter Number</th>
            <th>Chapter Name</th>
          </tr>
        </thead>
        <tbody>
          {chapters.map((chapter) => (
            <tr key={chapter.chapterNumber} onClick={() => onSelectChapterNumber(chapter.chapterNumber)}>
              <td>{chapter.chapterNumber}</td>
              <td>{chapter.chapterName} ({chapter.verseCount})</td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  ) : (<div className='loader'><img src={loader} alt="loading..." /></div>);
}

export default ChapterList;
