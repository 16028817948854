import React, { useEffect, useState } from 'react';
import axios from 'axios';
import apiMap from '../../apiMapping.json';
import loader from '../../assets/images/loading.gif'
import music from '../../assets/music/001_001.mp3'
import './SlokaSummary.css'


function SlokaSummary({ audio, language, chapterNumber, slokaNumber }) {
  const [chapters, setChapters] = useState([]);
  const [yogaName, setYogaName] = useState('');
  const [chapterName, setChapterName] = useState('');
  const [meaning, setMeaning] = useState('');
  const [summary, setSummary] = useState([]);
  const [commentary, setCommentary] = useState([]);
  const [synonym, setSynonym] = useState([]);
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!chapterNumber || !slokaNumber) return;

    const fetchData = async () => {
      try {
        setLoaded(false)
        // Fetch chapter summary
        const chapterResponse = await axios.get(apiMap.hostData+apiMap.apiData+`chapter/${language}/${chapterNumber}`);
        const chaptersData = Array.isArray(chapterResponse.data) ? chapterResponse.data : [chapterResponse.data];

        setChapters(chaptersData);
        if (chaptersData.length > 0) {
          setYogaName(chaptersData[0].yogaName || ''); // Update this based on actual data structure
          setChapterName(chaptersData[0].chapterName || ''); // Update this based on actual data structure
          setMeaning(chaptersData[0].meaning || ''); // Update this based on actual data structure
        }

        // Fetch sloka summary
        const summaryResponse = await axios.get(apiMap.hostData+apiMap.apiData+`sloka/${language}/${chapterNumber}/${slokaNumber}`);
        setSummary(Array.isArray(summaryResponse.data) ? summaryResponse.data : [summaryResponse.data]);

        // Fetch commentary
        const commentaryResponse = await axios.get(apiMap.hostData+apiMap.apiData+`commentary/${language}/${chapterNumber}/${slokaNumber}`);
        setCommentary(Array.isArray(commentaryResponse.data) ? commentaryResponse.data : [commentaryResponse.data]);

        // Fetch commentary
        const synonymResponse = await axios.get(apiMap.hostData+apiMap.apiData+`synonym/${language}/${chapterNumber}/${slokaNumber}`);
        setSynonym(Array.isArray(synonymResponse.data) ? synonymResponse.data : [synonymResponse.data]);

        setLoaded(true)
        setError(null);
      } catch (err) {
        setError('Failed to fetch data');
        console.error(err);
      }
    };

    fetchData();
  }, [audio,language,chapterNumber, slokaNumber]);

  function nth(n){return["st","nd","rd"][((n+90)%100-10)%10-1]||"th"}

  if (error) return <p>{error}</p>;

  const backgroundImageUrl = `https://thegita.org.in/images/chapters/${chapterNumber}.jpeg`;

  return loaded ? (
    <div className='sloka-summary-container'>
      <div className='sloka-heading' 
        style={{
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%), url(${backgroundImageUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center"  // Center the image
        }}>
        <h4 className='yoga-name'>{yogaName}</h4>
        <div className='chapter-title-container'>
          <div className='chapter-title'>
            <h3 className='chapter-name'>{chapterName}</h3>
            <span className='chapter-meaning'>{meaning}</span>
          </div>
          <h4 className='sloka-number'>{slokaNumber}<sup>{nth(slokaNumber)}</sup>  Sloka</h4>
        </div>
      </div>

      {summary.length > 0 ? (
        summary.map((item, index) => (
          <div key={index} className='sloka-container'>
            <div className='sloka-text'>
              <h4><span className='quote'>"</span>{item.sloka}<span className='quote'>"</span></h4>
              <div className='audio-player'><audio controls src={`https://thegita.org.in/audio/slokas/${audio}/Chapter${chapterNumber}/${chapterNumber}_${slokaNumber}.mp3`}></audio></div>
            </div>
            <div className='sloka-description'>
              <div className='summary-container'>
                <div className='meaning-container'>
                  <div className='heading'>
                    <span>Meaning</span>
                    <div className='mini-audio-player'><audio controls src={`https://thegita.org.in/audio/meanings/${language}/${chapterNumber}/Chapter${chapterNumber}_Sloka_${slokaNumber}.mp3`}></audio></div>
                  </div>
                  <div className='sloka-meaning'>{item.meaning}</div>
                </div>
                <div className='commentary-container'>
                <div className='heading'>
                    <span>Commentary</span>
                    {/* <div className='mini-audio-player'><audio controls src={music}></audio></div> */}
                  </div>
                  {commentary.length > 0 ? (
                    commentary.map((item, index) => (
                        <div key={index} className='commentary'>{item.commentary}</div>
                    ))
                  ) : (
                    <div>No Commentary available</div>
                  )}
                </div>
              </div>
              <div className='synonyms'>
                <div className='heading'>Synonyms</div>
                <table>
                  <thead>
                    <tr>
                      <th>Word</th>
                      <th>Meaning</th>
                    </tr>
                  </thead>
                  <tbody>
                    {synonym.length > 0 ? (
                      synonym.map((item, index) => (
                        <tr key={index} >
                          <td>{item.word}</td>
                          <td>{item.meaning}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">No synonym available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>No Sloka available</div>
      )}
    </div>
  ) : (<div className='loader'><img src={loader} alt="loading..." /></div>);
}

export default SlokaSummary;
