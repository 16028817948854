import React, { useState } from 'react';
import { GitaHeader } from './components/GitaHeader/GitaHeader';
import ChapterList from './components/ChapterList/ChapterList';
import SlokaList from './components/SlokaList/SlokaList';
import SlokaSummary from './components/SlokaSummary/SlokaSummary';
import './App.css'

const App = () => {
  const [language, setLanguage] = useState('en');
  const [audio, setAudio] = useState('male');
  const [chapterNumber, setChapterNumber] = useState(1);
  const [slokaNumber, setSlokaNumber] = useState(1);

  return (
      <div className='the-gita'>
        <div className='gita-header-wrapper'>
          <GitaHeader onLanguageChange={setLanguage} onAudioChange={setAudio}/>
          </div>
          <div className='sloka-reader'>
            <div className='chapters-container'>
              <div className='chapter-list-wrapper'>
                  <ChapterList language={language} selectedChapter={chapterNumber} onSelectChapterNumber={(number) => {setChapterNumber(number); setSlokaNumber(1)} } />
              </div>

              <div className='sloka-list-wrapper'>
                  {chapterNumber && <SlokaList language={language} chapterNumber={chapterNumber} selectedSloka={slokaNumber} onSelectSlokaNumber={setSlokaNumber} />}
              </div>
            </div>

              <div className='sloka-summary-wrapper'>
                  {chapterNumber && slokaNumber && <SlokaSummary audio={audio} language={language} chapterNumber={chapterNumber} slokaNumber={slokaNumber} />}
              </div>
          </div>
      </div>
  );
};

export default App;

